import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import CardFormItemUtil ,{ICardFormItemDataObj} from "./cardFormItemUtil";
export default defineComponent ({
    name: "cardItem",
    props:{
        ownerInst:{type: Object, default:()=>{return{}}},//引用该组件的组件（这里是业务卡片）
        disabled:{type: Boolean, default:false},//是否禁用组件
        item:{type: Object, default:()=>{return{}}},
        formRef:{type: Object, default:()=>{return{}}},
        form:{type: Object, default:()=>{return{}}},//外界的form表单，这里改变了，外界也跟着改变，不是说不能改变props吗?
        refMap:{type: Object, default:()=>{return new Map()}},//外界的refMap表单，这里改变了，外界也跟着改变，不是说不能改变props吗?
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ICardFormItemDataObj=reactive<ICardFormItemDataObj>({
            utilInst:{} as any,
            otherParams:{

            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CardFormItemUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            await dataObj.utilInst.init();
        })
        return{
            ...toRefs(dataObj)
        }
    }
});